<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Agent Group Form
    </template>
    <template slot="body">
      <form-text
        v-model="form.name"
        icon="description"
        label="Name"
      />

      <template v-if="form.agents.length">
        <h4>Agents</h4>
        <ul>
          <li
            v-for="(a,index) of form.agents"
            :key="index"
          >
            {{ a.name }} <small
              v-if="a.agent_agent_group.is_boss"
              class="text-success"
            >Administrator</small>
          </li>
        </ul>
      </template>
    </template>
    <template slot="footer">
      <md-button
        class="md-success float-right"
        @click="saveItem"
      >
        <md-icon class="mr-2">
          save
        </md-icon>
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText,
} from '@/components/Inputs';

export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        agents: [],
        name: null,
      },
    };
  },
  mounted() {
    this.form = { ...this.item };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveItem() {
      if (this.form.agent_group_id) {
        this.request(`agent_groups/${this.form.agent_group_id}`, 'put', this.form, () => {
          this.fireSuccess('Agent Group Updated Succesfully');
          this.$emit('save');
          this.close();
        });
      } else {
        this.request('agent_groups', 'post', this.form, () => {
          this.fireSuccess('Agent Group Created Succesfully');
          this.$emit('save');
          this.close();
        });
      }
    },
  },
};
</script>
<style></style>
